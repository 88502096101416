/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import HttpRequest from '../../utils/HttpRequest';
import Constants from '../../utils/Constants';

import {setHistoricalSchedules, setUpcomingSchedules} from "./DashboardActions";

/**
 * Server API call to get all upcoming schedule Consults
 * - Dispatch setUpcomingSchedules action after successful response from server
 * @returns {function(*)}
 */
export function getUpcomingSchedulesRequest(data) {
  return (dispatch) => {
    return new HttpRequest(dispatch, Constants.API_ACTIONS.UPCOMING_SCHEDULES, 'POST', data)
      .send()
      .then(res => {
        dispatch(setUpcomingSchedules(res.data.data, data.getCurrentDaySchedule));
        return Promise.resolve(res.data.data);
      }, error => {
        return Promise.reject(error);
      });
  };
}

/**
 * Server API call to get all historical schedule Consults
 * - Dispatch setHistoricalSchedules action after successful response from server
 * @returns {function(*)}
 */
export function getHistoricalSchedulesRequest(data) {
  return (dispatch) => {
    return new HttpRequest(dispatch, Constants.API_ACTIONS.HISTORICAL_SCHEDULES, 'POST', data)
      .send()
      .then(res => {
        dispatch(setHistoricalSchedules(res.data));
        return Promise.resolve(res.data.data);
      }, error => {
        return Promise.reject(error);
      });
  };
}

export function fetchMeetingInfoRequest(data) {
  return (dispatch) => {
    return new HttpRequest(dispatch, Constants.API_ACTIONS.FETCH_MEETING_INFO, 'POST', data)
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }, error => {
        return Promise.reject(error);
      });
  };
}

/**
 * Server API call to upload course image
 * - Dispatch uploadCourseImage action after successful response from server
 * @returns {function(*)}
 */
export const uploadVideoRequest = (data) => {
  return async (dispatch) => {
    try {
      const httpRequest = new HttpRequest(dispatch, `${Constants.API_ACTIONS.UPLOAD_URL}`, 'post', data);
      const res = await httpRequest.send("https://wemotive.in/api/v2");
      return res.data;
    } catch (error) {
      throw error;
    }
  };
};

export const uploadToS3Request = (url, file) => {
  return async (dispatch) => {
    try {
      const httpRequest = new HttpRequest(dispatch, url, 'put', file);
      const res = await httpRequest.s3Upload();
      return res;
    } catch (error) {
      throw error;
    }
  };
};