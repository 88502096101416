/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from "react-router-dom";

/**
 * Login page shown to the user when session is not present
 */
class Home extends React.Component {

  render() {
    if (this.props.loggedInUser && (this.props.loggedInUser.roles.includes("ROLE_ACADEMY_ADMIN") || this.props.loggedInUser.roles.includes("ROLE_SUPER_ADMIN") || this.props.loggedInUser.roles.includes("ROLE_ASSESSOR_PLUS")))
      return (<Redirect to="/dashboard/upcoming"/>);
    else
      return (<Redirect to="/signin"/>);
  }
}

const mapStateToProps = state => {
  return {
    loggedInUser: state.auth.loggedInUser
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
};

Home = connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);

export default Home;
