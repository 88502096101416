/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {Button, Grid, Tooltip} from "@material-ui/core";
import MaterialTable from 'material-table';

import Constants from '../../../../../../utils/Constants';
import './UpcomingExams.css';
import {getUpcomingSchedulesRequest} from '../../../../DashboardApiActions';
import UtilHelper from '../../../../../../utils/UtilHelper';
import MessageModal from "../../../../../../utils/components/MessageModal/MessageModal";

class UpcomingExams extends React.Component {
  constructor(props) {
    super(props);
    this.paginationParams = {
      page: Constants.CURRENT_PAGE,
      rowsPerPage: Constants.ROW_PER_PAGE,
      role: Constants.SYSTEM_ROLES.ROLE_PRACTICE_ADMIN
    };
    this.state = {
      paginationParams: Object.assign({}, this.paginationParams)
    }
  }

  componentDidMount = () => {
    this.getUpcomingSchedules();
  };

  getUpcomingSchedules = () => {
    this.props.getUpcomingSchedules({getCurrentDaySchedule: true}).then(() =>
      this.props.getUpcomingSchedules({getCurrentDaySchedule: false, displayLength: 100, start: 0})
    )};

  startExam = (examDetails, isExamStartFirstTime) => {
    sessionStorage.setItem('scheduleId', examDetails.scheduleId);
    sessionStorage.setItem('examStartTime', examDetails.startTime);
    sessionStorage.setItem('examEndTime', examDetails.endTime);
    sessionStorage.setItem('isExamStartFirstTime', '' + isExamStartFirstTime);
    window.location = '/examCall';
  };

  render() {
    const {upcomingSchedules} = this.props;

    const columns = [{
      title: 'Course',
      field: 'courseName',
    }, {
      title: 'Exam',
      field: 'examName',
    }, {
      title: 'Exam Date',
      field: 'examDate',
      render: props => {
        return moment(new Date(props.examDate)).format("DD-MM-YYYY");
      }
    }, {
      title: 'Start Time',
      field: 'startTime',
      render: props => {
        return UtilHelper.formatDateFromTime(props.startTime);//moment(new Date(props.startTime)).format("h:mm A") + ' - ' + moment(new Date(props.endTime)).format("h:mm A")
      }
    }, {
      title: 'Buffer Start Time',
      field: 'startTimeWithBuffer',
      render: props => {
        return UtilHelper.formatDateFromTime(props.startTimeWithBuffer);//moment(new Date(props.startTime)).format("h:mm A") + ' - ' + moment(new Date(props.endTime)).format("h:mm A")
      }
    }, {
      title: 'End Time',
      field: 'sendTimetartTimeWithBuffer',
      render: props => {
        return UtilHelper.formatDateFromTime(props.endTime);//moment(new Date(props.startTime)).format("h:mm A") + ' - ' + moment(new Date(props.endTime)).format("h:mm A")
      }
    }, {
      title: 'Buffer End Time',
      field: 'startTimeWithBuffer',
      render: props => {
        return UtilHelper.formatDateFromTime(props.maximumAllowedEndTimeWithBuffer + props.endTime);//moment(new Date(props.startTime)).format("h:mm A") + ' - ' + moment(new Date(props.endTime)).format("h:mm A")
      }
    }, {
      title: 'No. of Students',
      field: 'numberOfStudents',
      cellStyle: {
        textAlign: 'center'
      },
      headerStyle: {
        textAlign: 'center'
      }
    }, {
      title: 'Action',
      field: '-',
      render: props => {
        let h = new Date().getHours();
        let m = new Date().getMinutes();
        let currentTime = UtilHelper.getTimeInMilliseconds(h, m);
        if (currentTime > (props.maximumAllowedEndTimeWithBuffer + props.endTime)) {
          return "";
        } else {
          if (props.isExamStarted) {
            return (
              <>
                <Tooltip placement="top" title="Join Now">
                  <Button size="small" variant="outlined" color="primary" onClick={() => this.startExam(props, false)}>
                    <span className="blue-blink-dot"/> Join Now</Button>
                </Tooltip>
              </>
            );
          } else {
            if (currentTime > props.startTimeWithBuffer) {
              return "";
            } else {
              return (
                <>
                  <Tooltip placement="top" title="Start Exam">
                    <Button size="small" variant="outlined" color="primary"
                            onClick={() => this.startExam(props, true)}>Start</Button>
                  </Tooltip>
                </>
              )
            }
          }
        }
      }
    }];

    return (
      <>
        <MaterialTable
          scrollable
          columns={columns}
          data={upcomingSchedules ? upcomingSchedules : []}
          options={{
            toolbar: false,
            search: false,
            pageSize: this.state.paginationParams.rowsPerPage,
            horizontalScroll: SVGComponentTransferFunctionElement,
            sorting: false,
            draggable: false,
            pageSizeOptions: [10, 20, 30],
            emptyRowsWhenPaging: false
          }}
        />

        <MessageModal
          ref="messageModal"/>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    loggedInUser: state.auth.loggedInUser,
    upcomingSchedules: state.upcomingSchedules.upcomingSchedules
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getUpcomingSchedules: (data) => {
      return dispatch(getUpcomingSchedulesRequest(data)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });

    }
  }
};

UpcomingExams = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpcomingExams);

export default UpcomingExams;
