/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

// Export Constants
export const UPCOMING_SCHEDULES = 'UPCOMING_SCHEDULES';
export const HISTORICAL_SCHEDULES = 'HISTORICAL_SCHEDULES';

/**
 * Action called after page changed
 * @param data
 * @param getCurrentDaySchedule
 */
export function setUpcomingSchedules(data, getCurrentDaySchedule) {
  return {
    type: UPCOMING_SCHEDULES,
    data,
    getCurrentDaySchedule
  };
}

/**
 * Action called after page changed
 * @param data
 */
export function setHistoricalSchedules(data) {
  return {
    type: HISTORICAL_SCHEDULES,
    data
  };
}
