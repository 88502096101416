/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import React from 'react';
import S3 from 'react-aws-s3';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import AppLoader from "../../../../../../utils/components/AppLoader/AppLoader";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class AWSFileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.state = {
      showLoader: false,
      isFileSelected: false,
      is_recording_uploaded: false,
      snackbar_severity: '',
      snackbar_message: ''
    }
  }

  handleUpload = (event, scheduleId) => {
    this.setState({
      showLoader: true
    }, async () => {
      event.preventDefault();
      let file = this.fileInput.current.files[0];
      let newFileName = this.fileInput.current.files[0].name;

      const signedUrlRes = await this.props.uploadVideo({path: `${scheduleId}/${newFileName}`});
      const s3UploadRes = await this.props.uploadToS3(signedUrlRes.urls, file);
      if(s3UploadRes && s3UploadRes.status === 200){
        this.setState({
          showLoader: false,
          is_recording_uploaded: true,
          snackbar_severity: s3UploadRes.status === 200 ? 'success' : 'error',
          snackbar_message: s3UploadRes.status === 200 ? 'The exam recording has been uploaded successfully' : 'The exam recording has not been uploaded, please try again'
        }, () => {
        });
      }
    })
  };

  handleCloseSnackbar = () => {
    this.setState({
      is_recording_uploaded: false,
      snackbar_severity: '',
      snackbar_message: ''
    })
  };

  onFileChange = (event) => {
    if (event.target.files.length > 0) {
      this.setState({
        isFileSelected: true
      })
    } else {
      this.setState({
        isFileSelected: false
      })
    }
  };

  render() {
    const {scheduleId} = this.props;
    const {showLoader, is_recording_uploaded, snackbar_severity, snackbar_message} = this.state;

    return (
      <>
        <input
          className="inputFile"
          id="contained-button-file"
          ref={this.fileInput}
          onChange={(event) => this.handleUpload(event, scheduleId)}
          type="file"
        />
        <label htmlFor="contained-button-file">
          <Button className="upload-recording-btn" variant="contained" color="primary" size="small" component="span">
            Upload Recording
          </Button>
        </label>

        <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                  open={is_recording_uploaded}
                  onClose={this.handleCloseSnackbar}
                  autoHideDuration={600000}>
          <Alert severity={snackbar_severity} onClose={this.handleCloseSnackbar}>
            {snackbar_message}
          </Alert>
        </Snackbar>

        <AppLoader showLoader={showLoader}/>
      </>
    )
  }

}

export default AWSFileUpload;
