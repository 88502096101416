/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import {HISTORICAL_SCHEDULES, UPCOMING_SCHEDULES} from './DashboardActions';

const initialState = {
  upcomingSchedules: [],
  historicalSchedules: [],
};

/**
 * Reducer to update the state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */
const DashboardReducer = (state = initialState, action) => {

  switch (action.type) {
    case UPCOMING_SCHEDULES:
      if (action.getCurrentDaySchedule) {
        return Object.assign({}, state, {upcomingSchedules: action.data});
      } else {
        const upcomingSchedules = [...state.upcomingSchedules, ...action.data];
        return Object.assign({}, state, {upcomingSchedules});
      }

    case HISTORICAL_SCHEDULES:
      return Object.assign({}, state, {
        historicalSchedules: action.data,
      });

    default:
      return state;
  }
};

export default DashboardReducer;
