class UtilHelper {
  static blockSpecialCharacter = (value) => {
    const regex = /^[0-9a-zA-Z '.-]+$/;
    if (value && !regex.test(value)) {
      return false;
    } else {
      return true
    }
  }

  static validateObject(obj) {
    for (var o in obj)
      if (!obj[o]) return false;

    return true;
  }

  static getBase64(img, callback) {
    var b64Response = btoa(unescape(encodeURIComponent(img)));
    callback('data:image/jpeg;base64,' + b64Response);
  }

  static formatDateFromTime = (elapsed, dateString = true) => {
    elapsed = elapsed/1000;
    let clock = '';
    let hour = Math.floor(elapsed / 60 / 60);
    const minute = Math.floor(elapsed / 60 - hour * 60);
    const second = elapsed % 60;
    if (dateString) {
      if (hour >= 12) {
        hour -= 12;
        if (hour === 0) {
          hour = 12;
        }
        clock = ' PM';
      } else {
        clock = ' AM';
      }
    }
    const time = [hour, minute, second]
      .map((x) => (x < 10 ? '0' + x : x))
      .join(':');
    return time + clock;
  };

  static getTimeInMilliseconds = (hour, minute) =>{
    let hourInMilisecond = hour * 60 * 60;
    let minuteInMilisecond = minute * 60;

    return (hourInMilisecond + minuteInMilisecond) * 1000;
  }
}

export default UtilHelper;
