/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import HttpRequest from '../../utils/HttpRequest';
import Auth from '../../utils/Auth';
import Constants from '../../utils/Constants';

import {signIn} from "./AuthenticationActions";

/**
 * Request the server to validate user credentials and get the access token
 * @param credentials
 * @returns {function(*)}
 */
export function signInRequest(credentials) {
  return (dispatch) => {
    return new HttpRequest(dispatch, Constants.API_ACTIONS.LOGIN, 'post', {
      username: credentials.username,
      password: credentials.password
    })
      .send()
      .then(res => {

        let data = {};
        data.user = res.data.currentUserDetails;
        data.user.roles = res.data.roles;
        data.access_token = res.data.access_token;

        if(res.data.roles.includes("ROLE_ACADEMY_ADMIN") || res.data.roles.includes("ROLE_SUPER_ADMIN") || res.data.roles.includes("ROLE_ASSESSOR_PLUS")){
          // Store access token(JWT) and user details inside cookies
          Auth.login(data.user);
          Auth.setToken(data.access_token);
          dispatch(signIn(data));
        } else {
        }

        return Promise.resolve(data);
      }, error => {
        return Promise.reject(error);
      });
  };
}

/**
 * Request the server to validate user credentials and get the access token
 * @param credentials
 * @returns {function(*)}
 */
export function forgotPasswordRequest(credentials) {
  return (dispatch) => {
    return new HttpRequest(dispatch, Constants.API_ACTIONS.FORGOT_PASSWORD, 'post', {
      email: credentials.username,
    }).send()
      .then(res => {
        return Promise.resolve(res);
      }, error => {
        return Promise.reject(error);
      });
  };
}
