import React from "react";
import {Button, Grid} from "@material-ui/core";

const ExamStats = ({examStats, fullName, pauseExam, endExam, showPauseButton, mute, toggleMute, examSubmit = false}) => {
  return (
    <>
      <div className="student-full-name">
        <span>{fullName}</span>
        {showPauseButton && (
          <>
            <Button variant="contained" color="secondary"
                    onClick={pauseExam} className={"pause-exam"}>
              Pause Exam
            </Button>
            <Button variant="contained" color="secondary"
                    onClick={endExam} className={"pause-exam"}>
              End Exam
            </Button>
          </>
        )}
        {examSubmit ? (
          <Button variant="outlined" disabled className={"pause-exam"}>
            Exam Submitted
          </Button>
        ) : (
          <Button variant="outlined" onClick={toggleMute} className={"pause-exam"}>
            {mute ? 'Unmute' : 'Mute'}
          </Button>
        )}
      </div>
      <div className="countContainer">
        <Grid container spacing={0}>
          <Grid item={true} sm={4} xs={4}>
            <span className="stats-label">
              App Toggle:
            </span>
            <span className="font-style-bold padding-left stats-value">
              {examStats.appToggleCount}
            </span>
          </Grid>
          <Grid item={true} sm={4} xs={4}>
            <span className="stats-label">
              Internet Failure:
            </span>
            <span className="font-style-bold padding-left stats-value">
              {examStats.internetFailureCount}
            </span>
          </Grid>
          <Grid item={true} sm={4} xs={4}>
            <span className="stats-label">
              Video Failure:
            </span>
            <span className="font-style-bold padding-left stats-value">
              {examStats.videoCallFailureCount}
            </span>
          </Grid>
        </Grid>
      </div>
    </>
  )
};

export default ExamStats;
