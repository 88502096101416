/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from "react-router-dom";
import {withStyles} from '@material-ui/core/styles';
import {Box, Button, Container, CssBaseline, Grid, Typography} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';

import AppLogo from '../../../../assets/k11-logo.png'
import {forgotPasswordRequest} from '../../AuthenticationApiActions';
import './ForgotPassword.css';
import AppLoader from "../../../../utils/components/AppLoader/AppLoader";
import MessageModal from "../../../../utils/components/MessageModal/MessageModal";

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: '16px',
    marginBottom: '8px'
  },
});

/**
 * Forgot password page shown to the user when user reset password.
 */
class ForgotPassword extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      loginDetails: {
        username: '',
      }
    }
  }

  handleChange = (event) => {
    let loginDetails = Object.assign({}, this.state.loginDetails);
    loginDetails[event.target.name] = event.target.value;
    this.setState({loginDetails})
  };

  handleForgotPassword = () => {
    this.setState({showLoader: true}, () => {
      this.props.onForgotPassword(this.state.loginDetails).then((res) => {
        if (res) {
          this.setState({showLoader: false}, () => {
            this.refs.messageModal.setState({
              showModal: true,
              message: 'Password reset instruction send to your email.',
              title: 'Information',
              showOkButton: true,
              handleOk: () => {
                window.location = '/';
              },
              okText: 'Ok'
            });
          })

        }
      });
    });
  };

  redirectToLogin = () => {
    window.location = "/";
  };

  /**
   * Render the UI of the forgot password form
   * @returns {XML}
   */
  render() {
    const {classes} = this.props;
    const {loginDetails} = this.state;
    if (this.props.loggedInUser) {
      return <Redirect to="/"/>
    } else {
      return (
        <>
          <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
              <img className={classes.avatar} src={AppLogo} alt="App Logo" height="80" width="320"/>
              <Typography component="h1" variant="h5">
                Forgot Password
              </Typography>
              <ValidatorForm
                ref="form"
                onSubmit={this.handleForgotPassword}
                noValidate
                onError={errors => {
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <TextValidator
                      fullWidth
                      required
                      margin="normal"
                      variant="outlined"
                      label="Email" onChange={this.handleChange}
                      name="username" value={loginDetails.username}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Forgot
                    </Button>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <h4 className="login">
                      <span onClick={() => this.redirectToLogin()}>Back to login</span>
                    </h4>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </div>
            <Box mt={8}>
            </Box>
          </Container>

          <AppLoader showLoader={this.state.showLoader}/>

          <MessageModal
            ref="messageModal"
          />
        </>
      )
    }
  }

}

const mapStateToProps = state => {
  return {
    loggedInUser: state.auth.loggedInUser,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onForgotPassword: (data) => {
      return dispatch(forgotPasswordRequest(data)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    }
  };
};

ForgotPassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);

export default withStyles(styles)(ForgotPassword);
