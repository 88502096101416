/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import axios from 'axios';
// import Notification from './Notification';
import {sessionExpired} from "../modules/Authentication/AuthenticationActions";
import Auth from "./Auth";

// const notification = new Notification();

/**
 * Used to communicate with server
 */
class HttpRequest {

  /**
   * Send http request to server to write data to / read data from server
   * axios library provides promise implementation to send request to server
   * Here we are using axios library for requesting a resource
   */
  send = (mainUrl= "") => {
    // Content-Type and Authorization are default headers. If we want to send additional headers then add it to object
    let headers = {...this.headers, 'Content-Type': 'application/json'};
    const token = Auth.getToken();

    if(token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    return new Promise((resolve, reject) => {
      // Make server request using axios
      axios({
        baseURL: mainUrl?mainUrl:process.env.REACT_APP_SERVER_API_URL,
        // withCredentials: true,
        url: this.url,
        method: this.method,
        headers: headers,
        data: this.data,
      }).then(response => {
        resolve(response);
      }).catch(error => {
        // Users signature(JWT token) has been expired. Redirect user to Login page
        if (error.response === undefined) {
          // notification.show('error', 'Network issue', 'Something went wrong. Please try again.');
        } else if (error.response.status === 401) {
          if(this.dispatch)
            this.dispatch(sessionExpired(error.response.data.error));
        } else if(error.response.status === 428) {

        } else {
          // notification.show('error', 'Error', error.response.data.error);
        }
        reject(error);
      });
    });
  };

  upload = () => {
    // Content-Type and Authorization are default headers. If we want to send additional headers then add it to object
    let headers = {...this.headers, 'Content-Type': 'multipart/form-data'};

    return new Promise((resolve, reject) => {
      // Make server request using axios
      axios({
        baseURL: process.env.REACT_APP_SERVER_API_URL,
        withCredentials: true,
        url: this.url,
        method: this.method,
        headers: headers,
        data: this.data
      }).then(response => {
        resolve(response);
      }).catch(error => {
        // Users signature(JWT token) has been expired. Redirect user to Login page
        if (error.response === undefined) {
          // notification.show('error', 'Network issue', 'Something went wrong. Please try again.');
        } else if (error.response.status === 401) {
          if(this.dispatch)
            this.dispatch(sessionExpired(error.response.data.error));
        } else {
          // notification.show('error', 'Error', error.response.data.error);
        }
        reject(error);
      });
    });
  };

  s3Upload = (accessToken = null, isHideLoader = false, uploadProgress = undefined, contentType = undefined) => {
    // Content-Type and Authorization are default headers. If we want to send additional headers then add it to object
    let headers = {...this.headers, 'Content-Type': contentType ? contentType : 'multipart/form-data'};
    const CancelToken = axios.CancelToken;
    return new Promise((resolve, reject) => {
      // this.showHideLoader(this.dispatch);
      // Make server request using axios
      axios({
        withCredentials: true,
        url: this.url,
        method: this.method,
        headers: headers,
        data: this.data,
        isHideLoader: isHideLoader,
        // isS3UploadRequest: true,
        // trialNumber: 1,
        // maxTrial: parseInt(process.env.REACT_APP_MAX_NUMBER_OF_TRIAL_S3_UPLOAD_REQUEST),
        onUploadProgress: function (progressEvent) {
          if (uploadProgress) {
            progressEvent.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            uploadProgress(progressEvent);
          }
        },
      }).then(response => {
        resolve(response);
      }).catch(error => {
        if (error.response === undefined) {
        } else if (error.response.status === 401) {
          if (this.dispatch)
            this.dispatch(sessionExpired(error.response.data.error));
        } else if (error.response.status === 428) {

        } else {
          let message = 'Something went wrong. Please try again.';
          // this.dispatch(showSnackbar(error.response && error.response.data.error ? error.response.data.error : message, SNACKBAR_VARIAINT.ERROR));
        }
        if (axios.isCancel(error)) {
          error = {
            status: 499
          };
        }
        reject(error);
      });
    });
  };

  /**
   *
   * @param {*} url Resource URL
   * @param {*} method HTTP method(GET | POST | PUT | PATCH | DELETE)
   * @param {*} headers HTTP request headers
   * @param {*} data HTTP request data (If applicable)
   */
  constructor(dispatch, url, method = 'get', data = {}, headers = {}, options) {
    this.url = url;
    this.method = method;
    this.data = data;
    this.headers = headers;
    this.options = options;
    this.dispatch = dispatch;
  };
}

export default HttpRequest;
