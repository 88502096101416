/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import Auth from '../../utils/Auth';

// Export Constants
export const SIGN_IN = 'SIGN_IN';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const SIGN_OUT = 'SIGN_OUT';

/**
 * Actions related to the authentication module
 */

/**
 * Action called after sign in api call
 * @param data
 * @returns {{type: string, user: (*|number), accessToken: *}}
 */
export function signIn(data) {
  return {
    type: SIGN_IN,
    user: data.user,
    accessToken: data.access_token
  };
}


/**
 * User logout/signout action
 * @returns {{type: string, data: *}}
 */
export function signOut() {
  Auth.logout();
  return {
    type: SIGN_OUT
  }
}

export function sessionExpired(error = 'Session expired') {
  return {
    type: SESSION_EXPIRED,
    error
  }
}