/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from "react-router-dom"
import {withStyles} from '@material-ui/core/styles';
import {Box, Button, Container, CssBaseline, Grid, Typography} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';

import './SignIn.css';
import AppLogo from '../../../../assets/k11-logo.png'
import {signInRequest} from '../../AuthenticationApiActions';
import AppLoader from "../../../../utils/components/AppLoader/AppLoader";
import MessageModal from "../../../../utils/components/MessageModal/MessageModal";

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: '16px',
    marginBottom: '8px'
  },
});

/**
 * Login page shown to the user when session is not present
 */
class SignIn extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      loginDetails: {
        username: '',
        password: ''
      }
    }
  }

  handleChange = (event) => {
    let loginDetails = Object.assign({}, this.state.loginDetails);
    loginDetails[event.target.name] = event.target.value;
    this.setState({loginDetails})
  };

  handleSignIn = () => {
    this.setState({showLoader: true}, () => {
      this.props.onLogin(this.state.loginDetails).then((res) => {
        if (res) {
          // this.setState({showLoader: false}, () => {
            if(!res.user.roles.includes("ROLE_ACADEMY_ADMIN") && !res.user.roles.includes("ROLE_SUPER_ADMIN") && !res.user.roles.includes("ROLE_ASSESSOR_PLUS")){
              this.setState({showLoader: false}, () => {
                this.showLoginError("You are not authorized to sign in to this application");
              });
            }
          // });
        }
      }, (error) => {
        this.setState({showLoader: false}, () => {
          if (error.response && error.response.status === 401)
            this.showLoginError(error.response.data.error);
        });
      });
    });
  };

  redirectToForgotPassword = () => {
    window.location = "/forgotPassword";
  };

  /**
   * Render the UI of the login form
   * @returns {XML}
   */
  render() {
    const { classes } = this.props;
    const { loginDetails } = this.state;
    if (this.props.loggedInUser && (this.props.loggedInUser.roles.includes("ROLE_ACADEMY_ADMIN") || this.props.loggedInUser.roles.includes("ROLE_SUPER_ADMIN") || this.props.loggedInUser.roles.includes("ROLE_ASSESSOR_PLUS"))) {
      return <Redirect to="/"/>
    } else {
      return (
        <>
          <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
              <img className={classes.avatar} src={AppLogo} alt="App Logo" height="80" width="320"/>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <ValidatorForm
                ref="form"
                onSubmit={this.handleSignIn}
                noValidate
                onError={errors => {
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <TextValidator
                      fullWidth
                      required
                      margin="normal"
                      variant="outlined"
                      label="Email" onChange={this.handleChange}
                      name="username" value={loginDetails.username}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <TextValidator
                      fullWidth
                      required
                      margin="normal"
                      variant="outlined"
                      type="password"
                      label="Password" onChange={this.handleChange}
                      name="password" value={loginDetails.password}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Sign In
                    </Button>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <h4 className="forgot-password">
                      <span onClick={() => this.redirectToForgotPassword()}>Forgot Password?</span>
                    </h4>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </div>
            <Box mt={8}>
            </Box>
          </Container>

          <MessageModal
            ref="messageModal"
          />

          <AppLoader showLoader={this.state.showLoader}/>
        </>
      )
    }
  }

  /**
   * Show modal with the message and title received from the server
   * @param loginErrorMessage
   * @param loginErrorTitle
   */
  showLoginError(loginErrorMessage, loginErrorTitle = 'Invalid Credentials') {
    this.refs.messageModal.setState({
      showModal: true, message: loginErrorMessage, title: loginErrorTitle,
      showOkButton: true,
      handleOk: () => {
      },
      okText: 'Ok'
    });
  }

}

const mapStateToProps = state => {
  return {
    loggedInUser: state.auth.loggedInUser,
    signUp: state.auth.signUp
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onLogin: (data) => {
      return dispatch(signInRequest(data)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });

    }
  }
};

SignIn = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);

export default withStyles(styles)(SignIn);
