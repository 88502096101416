exports.DB_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_REALTIME_DATABASE_API_KAY,
  authDomain: process.env.REACT_APP_FIREBASE_REALTIME_DATABASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_REALTIME_DATABASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_REALTIME_DATABASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_REALTIME_DATABASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_REALTIME_DATABASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_REALTIME_DATABASE_APP_ID
};

exports.SYSTEM_ROLES = {
  SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ROLE_PRACTICE_ADMIN: 'ROLE_PRACTICE_ADMIN'
};

exports.ROW_PER_PAGE = 10;
exports.CURRENT_PAGE = 0;

exports.ACTIONS = {
  "DASHBOARD": "/dashboard/:tabName",
  "EXAM_CALL": "/examCall",
  "SIGN_IN": "/signin",
  "FORGOT_PASSWORD": '/forgotPassword'
};

exports.API_ACTIONS = {
  "LOGIN": "/login",
  "FORGOT_PASSWORD": "/guest/v1/user/forgotPassword",
  "UPCOMING_SCHEDULES": "/v1/scheduling/fetchUpcomingRemoteExamSchedules",
  "HISTORICAL_SCHEDULES": "/v1/scheduling/fetchExaminerRemoteScheduleHistory",
  "FETCH_MEETING_INFO": "/v1/remoteExamination/fetchMeetingsInfo",
  "UPLOAD_URL": "/uploadUrl"
};
