import React from 'react';
import { Button, Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';

import './MessageModal.css';

/**
 * User table component which shows all users data
 */
class MessageModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      message: '',
      title: '',
      showOkButton: false,
      showCancelButton: false,
      handleOk: null,
      handleCancel: null,
      okText: 'OK',
      cancelText: 'Cancel'
    }
  }

  render() {
    let footerButtons = [];

    if (this.state.showCancelButton) {
      footerButtons.push(
        <Button size="small" key="cancel" variant="outlined" onClick={() => {
          this.setState({ showModal: false }, () => {
            if (this.state.handleCancel !== null)
              this.state.handleCancel();
          });
        }}>
          {this.state.cancelText}
        </Button>);
    }

    if (this.state.showOkButton) {
      footerButtons.push(
        <Button size="small" key="ok" variant="contained" color="primary" onClick={() => {
          this.setState({ showModal: false }, () => {
            if (this.state.handleOk !== null)
              this.state.handleOk();
          });
        }}>
          {this.state.okText}
        </Button>);
    }

    return (
      <Dialog
        open={this.state.showModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className={"dialog-title"}>{this.state.title}</DialogTitle>
        <DialogContent className="dialog-content">
          {this.state.message}
        </DialogContent>
        <DialogActions className="actions-container">
          {footerButtons}
        </DialogActions>
      </Dialog>
    )
  }

}

export default MessageModal;
