/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import React from 'react';
import './UserDashboard.css';
import PropTypes from 'prop-types';
import {AppBar, Tab, Tabs} from "@material-ui/core";
import TableContainer from '@material-ui/core/TableContainer';

import UpcomingExams from './components/UpcomingExams/UpcomingExams';
import HistoricalExams from './components/HistoricalExams/HistoricalExams';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

class UserDashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0
    }
  }

  handleChange = (event, tabValue) => {
    // this.setState({ tabValue })
    if (tabValue === 0) {
      this.props.history.push("/dashboard/upcoming");
    } else if (tabValue === 1) {
      this.props.history.push("/dashboard/historical");
    }
  };

  componentDidMount = () => {
    let tabValue = 0;
    if (this.props.match.params.tabName === "upcoming") {
      tabValue = 0;
    } else {
      tabValue = 1
    }
    this.setState({tabValue})
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.match.params.tabName !== this.props.match.params.tabName) {
      let tabValue = 0;
      if (nextProps.match.params.tabName === "upcoming") {
        tabValue = 0;
      } else {
        tabValue = 1
      }
      this.setState({tabValue})
    }
  };

  render() {
    const {tabValue} = this.state;
    return (
      <>
        <TableContainer className="custom-table-container">
          <AppBar position="static" color="default">
            <Tabs value={tabValue} onChange={this.handleChange} aria-label="simple tabs example">
              <Tab label="Upcoming" {...a11yProps(0)} />
              <Tab label="Historical" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel className="tabPanel" value={tabValue} index={0}>
            <UpcomingExams/>
          </TabPanel>
          <TabPanel className="tabPanel" value={tabValue} index={1}>
            <HistoricalExams/>
          </TabPanel>
        </TableContainer>
      </>
    )
  }

}

export default UserDashboard;
