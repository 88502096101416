/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */
import HttpRequest from '../../utils/HttpRequest';
import Constants from '../../utils/Constants';

/**
 * Server API call to get the meeting information
 * @returns {function(*)}
 */
export function fetchMeetingInfoRequest(scheduleId) {
  let data = {
    examScheduleId: scheduleId
  };
  return (dispatch) => {
    return new HttpRequest(dispatch, Constants.API_ACTIONS.FETCH_MEETING_INFO, 'POST', data)
      .send()
      .then(res => {
        return Promise.resolve(res.data);
      }, error => {
        return Promise.reject(error);
      });
  };
}

/**
 * Server API call to get the consult information
 * - Dispatch consultReject action after successful response from server
 * @returns {function(*)}
 */
export function startConsult(consultId) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/consult/start`, 'post', {consultId})
      .send()
      .then(res => {
        return Promise.resolve(res.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

/**
 * Server API call to end the consult
 * @returns {function(*)}
 */
export function endConsult(consultId) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/consult/end`, 'post', {consultId})
      .send()
      .then(res => {
        return Promise.resolve(res.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

/**
 * Server API call to pause the consult
 * @returns {function(*)}
 */
export function pauseConsult(consultId) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/consult/pause`, 'post', {consultId})
      .send()
      .then(res => {
        return Promise.resolve(res.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}
