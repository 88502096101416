/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import React from 'react';
import {connect} from 'react-redux';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {AppBar, IconButton, Toolbar, Tooltip} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {withStyles} from '@material-ui/core/styles';

import {ACTIONS} from '../../../../utils/Constants';
import './Dashboard.css';
import UserDashboard from '../UserDashboard/UserDashboard';
import MessageModal from "../../../../utils/components/MessageModal/MessageModal";
import {signOut} from '../../../Authentication/AuthenticationActions';
import Auth from "../../../../utils/Auth";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    minheight: '48px',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: '#555555',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    minheight: '48px',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(9),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
});

class Dashboard extends React.Component {

  constructor(props) {
    super(props);

    this.menu = this.props.menu;

    this.state = {
      noActivityDetected: false,
      showLoader: false,
      pathAllowed: false,
      openDrawer: false,
      practice: undefined,
      connectAccountDetails: undefined,
      refreshDashboard: false
    }
  }

  /**
   * Method executes on side menu open
   */
  handleDrawerOpen = () => {
    this.setState({openDrawer: true});
  };

  /**
   * Method executes on side menu close
   */
  handleDrawerClose = () => {
    this.setState({openDrawer: false})
  };

  /*
   * Check if the user is idle from last minutes, if so log out the user from the system
   */
  detectNoActivity = () => {
    let IDLE_TIMEOUT = 30; //minutes
    let _idleSecondsCounter = 0;

    const self = this;

    document.onclick = function () {
      _idleSecondsCounter = 0;
    };

    document.onmousemove = function () {
      _idleSecondsCounter = 0;
    };

    document.onkeypress = function () {
      _idleSecondsCounter = 0;
    };

    let interval = window.setInterval(CheckIdleTime, 60000);

    function CheckIdleTime() {

      _idleSecondsCounter++;
      if (_idleSecondsCounter >= IDLE_TIMEOUT) {
        self.setState({noActivityDetected: true});
        clearInterval(interval);
      }
    }

  };

  /**
   * Method executes on every menu click action
   */
  handleMenuClick = (menu) => {
    // If logout menu is clicked then trigger sign out action
    if (menu.key === 'logout') {
      this.logoutUser();
    } else {
      document.getElementsByClassName('ant-menu-submenu-title')[0].click();
      this.menu = menu.key;
    }
  };

  /*
   * Method executes when request to logout
   */
  logoutUser = () => {
    this.refs.messageModal.setState({
      showModal: true, message: 'Are you sure you want to logout?', title: 'Logout',
      showOkButton: true,
      showCancelButton: true,
      handleOk: this.props.signOut,
      handleCancel: () => {
      },
      okText: 'Logout',
      cancelText: 'Cancel'
    });
  };

  /**
   * Check activity detect in last 30  when component is render
   * Check route authorized or not when component is render
   * Get logged in user practice method call when component is render
   * AttachComponent method call when component is render
   */
  componentDidMount() {

  }

  componentWillReceiveProps = (newProps) => {
    if (newProps.showSessionExpiredModal) {
      this.refs.messageModal.setState({
        showModal: newProps.showSessionExpiredModal,
        message: 'Session Expired, please login again.',
        title: 'Session Expired',
        showOkButton: true,
        handleOk: () => {
          this.clearSession()
        },
        handleCancel: () => {
        },
        okText: 'Ok',
      });
    }
  };
  /**
   * Method is used for check the router validate permission
   */
  validatePermissions = () => {
    const {menu, allowedResource} = this.props;
    let pathAllowed = allowedResource.indexOf(menu) >= 0;
    this.setState({pathAllowed});
  };

  /**
   * Method used to close the snackbar
   */
  handleCloseSnackbar = () => {
    this.props.clearSnackbar();
  };

  /**
   * Method executes session expired
   * navigate to login page method call
   */
  clearSession() {
    Auth.logoutAndNavigateToLogin();
  }

  render() {
    const {classes, loggedInUser} = this.props;
    return (
      !loggedInUser
        ? (
          <Redirect to="/"/>
        )
        : <>
          <Router>
            <div className={classes.root}>
              <AppBar
                position="fixed"
                color="inherit"
                className={classes.appBar}
              >
                <Toolbar>
                  <span className="header-name">Dashboard</span>
                  <span className="logout-icon">
                  <Tooltip placement="left" title="Logout">
                    <IconButton aria-label="Logout" onClick={this.logoutUser}>
                      <ExitToAppIcon/>
                    </IconButton>
                  </Tooltip>
                </span>
                </Toolbar>
              </AppBar>
              <main
                className={`${classes.content}`}
              >
                <div className="mainContainer">
                  <Switch>
                    <Route path={ACTIONS.DASHBOARD} render={(props) => {
                      return <UserDashboard pageTitle='Dashboard' {...props}/>
                    }}/>
                  </Switch>
                </div>
              </main>
            </div>
          </Router>

          <MessageModal
            ref="messageModal"/>
        </>
    );
  }

}

const mapStateToProps = state => {
  return {
    loggedInUser: state.auth.loggedInUser,
    showSessionExpiredModal: state.auth.showSessionExpiredModal,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    signOut: () => {
      dispatch(signOut())
    },
  }
};

Dashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default withStyles(styles)(Dashboard);
