import React, {useEffect, useRef, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import 'firebase/database';
import firebase from 'firebase/app';
import '@vonage/video-publisher/video-publisher.js';
import MuiAlert from '@material-ui/lab/Alert';

import './Video.css';
import {DB_CONFIG} from '../../../../utils/Constants';
import ExamStats from "./ExamStats";


const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Video = ({studentDetails, removeStudentFromExamHall}) => {
  const publisher = useRef(null);
  const subscribers = useRef(null);
  const [mute, setMute] = useState(true);
  const [subscriberConnected, setSubscriberConnected] = useState(false);

  const [examStats, setExamStats] = useState({
    appToggleCount: 0,
    internetFailureCount: 0,
    time: null,
    videoCallFailureCount: 0,
    examSubmit: false
  });
  const [dialogContent, setDialogContent] = useState({
    title: null,
    message: null,
    action: null,
  });

  const app = useRef();
  const database = useRef();
  const firstTime = useRef(true);

  const endCall = () => {
  };

  const pauseExam = () => {
    const examAttemptId = studentDetails.examAttemptId;
    const studentId = studentDetails.student.id;
    app.current.database().ref().child(`VideoStatus/${examAttemptId}/${studentId}`).set(false);
  };

  const endExam = () => {
    const examAttemptId = studentDetails.examAttemptId;
    const studentId = studentDetails.student.id;
    app.current.database().ref().child(`ExamStatus/${examAttemptId}/${studentId}`).set(false);
  };

  const toggleMute = () => {
    publisher.current.toggleAudio();
    setMute(!mute);
  }

  const handleCloseSnackbar = () => {
  };

  useEffect(() => {
    const handleStatsChange = snap => {
      const studentId = studentDetails.student.id;

      if (snap.val()) {
        const stats = snap.val();
        if (!firstTime.current && stats.examSubmit) {
          setDialogContent({
            title: 'Exam Submit',
            message: studentDetails.student.fullName + " has submitted the exam.",
            action: () => {
              endCall();
              removeStudentFromExamHall(studentId);
            }
          })
        } else if (firstTime.current && stats.examSubmit) {
          endCall();
          removeStudentFromExamHall(studentId);
        }

        firstTime.current = false;
        setExamStats(stats);
      }
    };

    const examAttemptId = studentDetails.examAttemptId;
    const studentId = studentDetails.student.id;

    if (!firebase.apps.length) {
      app.current = firebase.initializeApp(DB_CONFIG);
      database.current = app.current.database().ref().child(`ExamStats/${examAttemptId}/${studentId}`);
    } else {
      app.current = firebase;
      database.current = app.current.database().ref().child(`ExamStats/${examAttemptId}/${studentId}`);
    }

    database.current.on('value', handleStatsChange);

    const OT = window.OT;
    const session = OT.initSession(process.env.REACT_APP_TOKBOX_API_KEY, studentDetails.sessionId);
    publisher.current.session = session;
    publisher.current.token = studentDetails.token;
    publisher.current.properties = {
      audioFallback: {
        publisher: true,
        subscriber: false,
      },
      fitMode: 'contain',
      publishVideo: false,
      publishAudio: !mute,
    };
    session.connect(studentDetails.token);

    session.on('streamCreated', (event) => {
      const options = {
        height: "100%",
        width: "100%", insertMode: 'append', fitMode: 'contain',
      };
      const subscriber = session.subscribe(event.stream, studentDetails.student.id, options);
      setSubscriberConnected(true);
    });

    session.on('streamDestroyed', (event) => {
      setSubscriberConnected(false);
    });

    return () => {
      database.current.off('value', handleStatsChange);
      endCall();
      session.disconnect();
    }
  }, []);

  return (
    <div className="mainContainerDiv">
      {examStats.examSubmit ? (
        <ExamStats
          fullName={studentDetails.student.fullName}
          examStats={examStats}
          pauseExam={null}
          endExam={null}
          showPauseButton={false}
          examSubmit={true}/>
      ) : (
        <>
          <div className={'video-container'}>
            <video-publisher ref={publisher}></video-publisher>
            <div id={studentDetails.student.id}/>
          </div>
          <ExamStats
            fullName={studentDetails.student.fullName}
            examStats={examStats}
            pauseExam={pauseExam}
            endExam={endExam}
            showPauseButton={subscriberConnected}
            mute={mute}
            toggleMute={toggleMute}/>
        </>
      )}

      {dialogContent.title && (
        <Dialog
          open
          aria-labelledby="video-dialog-title"
        >
          <DialogTitle id="video-dialog-title" className={"dialog-title"}>
            {dialogContent.title}
          </DialogTitle>
          <DialogContent className="dialog-content">
            {dialogContent.message}
          </DialogContent>
          <DialogActions className="actions-container">
            <Button size="small" key="ok" variant="contained" color="primary" onClick={() => {
              dialogContent.action();
              setDialogContent({
                title: null,
                message: null,
                action: null,
              });
            }}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/*{backgroundWarning && (*/}
      {/*  <Box mt={2}>*/}
      {/*    <Alert severity={'warning'} onClose={handleCloseSnackbar}>*/}
      {/*      Caution: The student has either rotated the phone or attempted to switch the app.*/}
      {/*    </Alert>*/}
      {/*  </Box>*/}
      {/*)}*/}
    </div>
  );
};

export default Video;
