import React, {useEffect, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Toolbar,
  Typography
} from "@material-ui/core";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";

import Video from "../Video/Video";
import './ExamCall.css';
import AppLoader from "../../../../utils/components/AppLoader/AppLoader";
import {fetchMeetingInfoRequest} from '../../ConsultApiActions';
import Auth from "../../../../utils/Auth";
import UtilHelper from '../../../../utils/UtilHelper';

const styles = theme => ({
  rootGridHeight: {
    height: 'calc(100% - 48px)',
    position: 'relative',
  }
});

const ExamCall = ({fetchMeetingInfo, showSessionExpiredModal, loggedInUser, classes, showLoader}) => {
  const [examDetails, setExamDetails] = useState([]);
  const [availableStudentsInExamHall, setAvailableStudentsInExamHall] = useState([]);
  const [endCall, setEndCall] = useState(false);
  const [apiInProgress, setApiInProgress] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    title: null,
    message: null,
    action: null,
  });

  const requestMeetingInfo = (scheduleId) => {
    setApiInProgress(true);
    fetchMeetingInfo(scheduleId).then(res => {
      if (res.status) {
        const availableStudents = res.data.map(details => details.student.id);
        setExamDetails(res.data);
        setAvailableStudentsInExamHall(availableStudents);
        setApiInProgress(false);
      } else {
        setDialogContent({
          title: 'Error',
          message: res.message,
          action: () => {
            window.location = '/dashboard';
          },
        });
        setApiInProgress(false);
      }
    }, error => {
      //TODO: Handle this case
      console.log(error);
      setApiInProgress(false);
    });
  };

  const removeStudentFromExamHall = (studentId) => {
    const availableStudents = availableStudentsInExamHall.filter(id => id !== studentId);

    setAvailableStudentsInExamHall(availableStudents);
    if (availableStudents.length === 0) {
      setDialogContent({
        title: 'Alert',
        message: 'All students have submitted their exams. You can end the exam session now. Please upload the recording to the system.',
        action: () => {
          window.location = '/dashboard';
        },
      });
    }
  };

  /**
   * Method executes session expired
   * navigate to login page method call
   */
  const clearSession = () => Auth.logoutAndNavigateToLogin();

  const showConfirmationForEnd = () => {
    setEndCall(true);
    setAvailableStudentsInExamHall([]);
    window.location = '/dashboard';
  };

  const setBreakPointBasedOnNumberOfStudents = () => {
    const numberOfStudents = examDetails.length;
    switch (numberOfStudents) {
      case 1:
        return 12;
      case 2:
        return 6;
      case 3:
        return 6;
      case 4:
        return 6;
      default:
        return 4;
    }
  };

  const setHeightBasedOnNumberOfStudents = () => {
    const numberOfStudents = examDetails.length;
    switch (numberOfStudents) {
      case 1:
        return "fullHeight";

      case 2:
        return "fullHeight";

      case 3:
        return "height50";

      case 4:
        return "height50";

      case 5:
        return "height50";

      case 6:
        return "height50";

      default:
        return "height33";
    }
  };

  useEffect(() => {
    const scheduleId = sessionStorage.getItem('scheduleId');
    const isExamStartFirstTime = sessionStorage.getItem('isExamStartFirtTime');
    if (scheduleId != null) {
      if (isExamStartFirstTime === 'true') {
        setDialogContent({
          title: 'Start Recording',
          message: 'Please start the recording immediately. Please note that this is very important step. Do not skip it. Also, once exam ends, please upload the recording to the system.',
          action: () => {
            sessionStorage.setItem('isExamStartFirtTime', 'false');
            requestMeetingInfo(scheduleId);
          },
        });
      } else {
        requestMeetingInfo(scheduleId);
      }
    } else {
      window.location = '/dashboard';
    }
  }, []);

  useEffect(() => {
    if (showSessionExpiredModal) {
      setDialogContent({
        title: 'Session Expired',
        message: 'Session Expired, please login again.',
        action: clearSession,
      });
    }
  }, [showSessionExpiredModal]);

  if (examDetails === null && examDetails.length < 1) {
    return <Redirect to={'/dashboard/upcoming'}/>
  }

  if (apiInProgress) {
    return <AppLoader showLoader={apiInProgress}/>
  } else {
    return (
      <>
        <AppBar
          color="inherit"
          position="fixed">
          <Toolbar>
            <Typography variant="h6" noWrap className="exam-title">
              <span className="exam-app-header">Exam Duration: </span><span
              className="value-font">{UtilHelper.formatDateFromTime(sessionStorage.getItem('examStartTime')) + ' - ' + UtilHelper.formatDateFromTime(sessionStorage.getItem('examEndTime'))}</span>
            </Typography>
            <Button variant="contained" color="secondary" className={'end-consult-button'}
                    onClick={showConfirmationForEnd}>End</Button>
          </Toolbar>
        </AppBar>
        <main className={'consult-content'}>
          <div>
            <Grid container spacing={0}>
              {examDetails.length > 0 && examDetails.map((student, index) => {
                const breakPoint = setBreakPointBasedOnNumberOfStudents();
                return (
                  <Grid
                    key={index}
                    item
                    xl={breakPoint}
                    lg={breakPoint}
                    md={breakPoint}
                    sm={12} xs={12}
                    className={`${classes.rootGridHeight} ${setHeightBasedOnNumberOfStudents()}`}>
                    <div className={'exam-video-container'}>
                      <Video
                        studentDetails={student}
                        index={index}
                        endCall={endCall}
                        removeStudentFromExamHall={removeStudentFromExamHall}
                        loggedInUser={loggedInUser}/>
                    </div>
                  </Grid>
                )
              })}
            </Grid>
          </div>
        </main>

        <AppLoader showLoader={showLoader}/>

        {dialogContent.title && (
          <Dialog
            open
            aria-labelledby="exam-dialog-title"
          >
            <DialogTitle id="exam-dialog-title" className={"dialog-title"}>{dialogContent.title}</DialogTitle>
            <DialogContent className="dialog-content">
              {dialogContent.message}
            </DialogContent>
            <DialogActions className="actions-container">
              <Button size="small" key="ok" variant="contained" color="primary" onClick={() => {
                dialogContent.action();
                setDialogContent({
                  title: null,
                  message: null,
                  action: null,
                });
              }}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.auth.loggedInUser,
    showSessionExpiredModal: state.auth.showSessionExpiredModal
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMeetingInfo: (examScheduleId) => {
      return dispatch(fetchMeetingInfoRequest(examScheduleId)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });

    }
  }
};

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(ExamCall));
