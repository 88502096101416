/**
 * @author Vishal Bhandari <vishal@wemotiveforge.com>
 */

import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {Grid} from "@material-ui/core";
import MaterialTable from 'material-table';

import Constants from '../../../../../../utils/Constants';
import './HistoricalExams.css';
import {getHistoricalSchedulesRequest, uploadToS3Request, uploadVideoRequest} from '../../../../DashboardApiActions';
import UtilHelper from '../../../../../../utils/UtilHelper';
import MessageModal from "../../../../../../utils/components/MessageModal/MessageModal";
import AWSFileUpload from '../AWSFileUpload/AWSFileUpload';

class HistoricalExams extends React.Component {
  constructor(props) {
    super(props);
    this.paginationParams = {
      page: Constants.CURRENT_PAGE,
      rowsPerPage: Constants.ROW_PER_PAGE,
    };
    this.state = {
      paginationParams: Object.assign({}, this.paginationParams),
    }
  }

  componentDidMount = () => {
    let data = {
      displayLength: this.state.paginationParams.rowsPerPage,
      start: this.state.paginationParams.page * this.state.paginationParams.rowsPerPage
    };
    this.getHistoricalSchedules(data);
  };

  getHistoricalSchedules = (data) => {
    this.props.getHistoricalSchedules(data);
  };

  render() {
    const {historicalSchedules} = this.props;
    const columns = [{
      title: 'Course',
      field: 'courseName',
    }, {
      title: 'Exam',
      field: 'examName',
    }, {
      title: 'Exam Date',
      field: 'examDate',
      render: props => {
        return moment(new Date(props.examDate)).format("DD-MM-YYYY");
      }
    },
      {
        title: 'Start Time',
        field: 'startTime',
        render: props => {
          return UtilHelper.formatDateFromTime(props.startTime);//moment(new Date(props.startTime)).format("h:mm A") + ' - ' + moment(new Date(props.endTime)).format("h:mm A")
        }
      }, {
        title: 'End Time',
        field: 'endTime',
        render: props => {
          return UtilHelper.formatDateFromTime(props.endTime);//moment(new Date(props.startTime)).format("h:mm A") + ' - ' + moment(new Date(props.endTime)).format("h:mm A")
        }
      },
      {
        title: 'No. of Students',
        field: 'numberOfStudents',
        cellStyle: {
          textAlign: 'center'
        },
        headerStyle: {
          textAlign: 'center'
        }
      }, {
        title: 'Action',
        field: '-',
        render: props => {
          if (props.numberOfStudents > 0 && props.isExamStarted) {
            return (
              <AWSFileUpload 
                scheduleId={props.scheduleId}
                uploadVideo={this.props.uploadVideo}
                uploadToS3={this.props.uploadToS3}
              />
            )
          } else {
            return (
              <></>
            )
          }
        }
      }];

    return (
      <>
        <MaterialTable
          scrollable
          columns={columns}
          data={historicalSchedules ? historicalSchedules.data : []}
          totalCount={historicalSchedules ? historicalSchedules.recordsTotal : 0}
          pages={Math.ceil(historicalSchedules ? historicalSchedules.recordsTotal : 0 / this.paginationParams.rowsPerPage)}
          page={this.state.paginationParams.page}
          options={{
            toolbar: false,
            search: false,
            pageSize: this.state.paginationParams.rowsPerPage,
            horizontalScroll: SVGComponentTransferFunctionElement,
            sorting: false,
            draggable: false,
            pageSizeOptions: [],
            emptyRowsWhenPaging: false
          }}
          onChangePage={(page) => {
            let paginationParams = Object.assign({}, this.state.paginationParams);
            paginationParams.page = page;
            this.setState({
              paginationParams
            }, () => {
              let data = {
                displayLength: this.state.paginationParams.rowsPerPage,
                start: page * this.state.paginationParams.rowsPerPage
              };
              this.props.getHistoricalSchedules(data);
            });
          }}
        />

        <MessageModal
          ref="messageModal"/>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    loggedInUser: state.auth.loggedInUser,
    historicalSchedules: state.upcomingSchedules.historicalSchedules
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getHistoricalSchedules: (data) => {
      return dispatch(getHistoricalSchedulesRequest(data)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });

    },
    uploadVideo: async (data) => {
      try {
        return await dispatch(uploadVideoRequest(data));
      } catch (error) {
        throw error;
      }
    },
    uploadToS3: async (url, file) => {
      try {
        return await dispatch(uploadToS3Request(url, file));
      } catch (error) {
        throw error;
      }
    },
    
  }
};

HistoricalExams = connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoricalExams);

export default HistoricalExams;
