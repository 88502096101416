import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import SignIn from './modules/Authentication/components/SignIn/SignIn';
import ForgotPassword from './modules/Authentication/components/ForgotPassword/ForgotPassword';
import Dashboard from './modules/Dashboard/components/Dashboard/Dashboard';
import Home from './modules/Dashboard/components/Home/Home';
import ExamCall from './modules/ExamCall/components/ExamCall/ExamCall';

import {ACTIONS} from './utils/Constants';


/**
 * Used decide the component to render depending on the current route of the application
 * @returns {XML}
 * @constructor
 */
function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/dashboard" component={Home}/>
        <Route path={ACTIONS.SIGN_IN} component={SignIn}/>
        <Route path={ACTIONS.FORGOT_PASSWORD} component={ForgotPassword}/>
        <Route path={ACTIONS.DASHBOARD} component={(props) => <Dashboard {...props} menu={'dashboard'}/>}/>
        <Route path={ACTIONS.EXAM_CALL} component={(props) => <ExamCall {...props} menu={'examCall'}/>}/>
      </Switch>
    </Router>
  );
}

export default Routes;
