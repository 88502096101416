import React from 'react';
import { ThemeProvider } from '@material-ui/styles';

import './App.css';
import Routes from './routes';
import theme from './utils/components/theme';

const App = () => (
  <ThemeProvider theme={theme}>
    <Routes/>
  </ThemeProvider>
);

export default App;
